<template>
  <layout-stepper>
    <template #body>
      <b-link @click="$router.push({name: 'step_location'})" v-if="!has_slug">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        Previous Step
      </b-link>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">Let's make a deal!</h1>
            <p>These are the specific offerings and deliverables you want to sell to buyers. Your listing can have more than one deal for buyers to choose from.</p>
            <b-button variant="flat-secondary" class="my-2 p-2" @click="addDeal" :disabled="deals.length > 3">
              <feather-icon icon="PlusIcon"></feather-icon>  
              Add Deal
            </b-button>
            <b-pagination
              pills
              v-model="current_page"
              :total-rows="deals.length"
              :per-page="per_page"
              class="justify-content-center"
              v-if="deals.length > 1"
            ></b-pagination>
            <div v-for="(deal, index) in dealsFiltered()" :key="index">
              <div class="text-danger text-right cursor-pointer" @click="deleteDeal(deal)" v-if="deals.length > 1">Eliminar</div>
              <b-form-group label="Title" label-for="input-title" class="form-group-layout">
                <b-form-input id="input-title" v-model="deal.title" trim></b-form-input>
              </b-form-group>
              <b-form-group label="Description" label-for="input-description" class="form-group-layout">
                <b-form-textarea id="input-description" v-model="deal.description" rows="3"></b-form-textarea>
              </b-form-group>
              <div class="d-flex">
                <strong class="text-left">Feautures</strong>
                <p class="text-muted ml-1">(Select)</p>
              </div>
              <strong class="text-danger col-12 mt-1 h4" v-if="deal.features.length === 0">You must to select at least one feature</strong>
              <b-form-group class="mt-1">
                <b-form-checkbox-group
                  v-model="deal.features"
                  :options="$t('creator.features')"                
                  buttons
                >
                </b-form-checkbox-group>
              </b-form-group>
              <b-form-group label="Price" label-for="input-price" class="form-group-layout">
                <b-input-group prepend="$" id="input-price">
                  <cleave
                    v-model="deal.price"
                    :options="options_number"
                    class="form-control"
                    :raw="false"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group label="Days For Delivery" label-for="input-delivery" class="form-group-layout">
                <b-form-select :options="days_delivery_options" id="input-delivery" v-model="deal.days_delivery"></b-form-select>
              </b-form-group>
              <b-form-group label="Number of Revision" label-for="input-revision" class="form-group-layout">
                <b-form-select :options="number_of_revisions_options" id="input-revision" v-model="deal.revision"></b-form-select>
              </b-form-group>
              <b-form-group label="Licensing" label-for="input-licensing" class="form-group-layout">
                <b-form-select :options="$t('creator.licensing')" id="input-licensing" v-model="deal.licensing"></b-form-select>
              </b-form-group>
            </div>
            <b-col class="col-12 d-flex justify-content-center">
              <b-button variant="primary" class="my-2" :disabled="false" @click="save()">{{has_slug ? 'Save and preview' : 'Next step'}}</b-button>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormTextarea,
  BInputGroup,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import utils from '@/libs/utils';
import Cleave from 'vue-cleave-component';
import creator_services from '@/services/creator';

export default {
  name: 'deals',
  components: {
    BRow,   
    BCol,
    BButton,
    BLink,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckboxGroup,
    layoutStepper,
    BInputGroup,
    BFormSelect,
    BPagination,
    Cleave,
  },
  data() {
    return {
      utils,
      steps: null,
      deals: [
        {
          index: Math.random().toString(36).substr(2, 18),
          price: 335,
          title: 'Any title',
          description: 'Any description',
          features: ['share_your_content', 'create_custom_content', 'ship_me_your_product', 'brand_mention', 'product_placement'],
          days_delivery: 15,
          revision: 2,
          licensing: 'sponsored_social_content'
        },
      ],
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      days_delivery_options: [1, 2, 3, 7, 10, 15, 30],
      number_of_revisions_options: [1, 2, 3, 4, 5],
      current_page: 1,
      per_page: 1,
      proposal: {}
    }
  },
  computed: {
    has_slug() {
      return this.$route.params.slug;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    dealsFiltered () {
      const deals = this.deals;
      return deals.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page);
    },
    getData() {
      if (this.$route.params.slug !== undefined) { 
        creator_services.detailProposal(this.$route.params.slug).then((response) => {
          this.deals = response.deals;
          this.deals.map((deal) => {
            deal.features = deal.features.map((feature) => feature.title);
          });
          this.proposal = response;
        });
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        if (this.steps.deals) this.deals = this.steps.deals;
      }
    },
    save() {  
      this.deals.map((deal) => {
        if (isNaN(deal.price)) deal.price = parseInt(deal.price.split(',').join(''));
      });
      if (this.$route.params.slug !== undefined) {
        this.deals.map((deal) => {
          if (deal.uuid) {
            creator_services.updateElement(this.proposal.uuid, deal.uuid, deal, 'deal');
          } else {
            creator_services.addOptionOrRequest(this.proposal.uuid, {deals: [deal]}, 'deal')
          }
        });
        this.$router.push({name: 'step_preview', params: {slug: this.proposal.slug}});
      } else {
        this.steps['deals'] = this.deals;
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({name: 'step_samples'});
      }
    },
    addDeal() {
      if (this.deals.length < 4) {
        this.deals.push(
          {
            index: Math.random().toString(36).substr(2, 18),
            price: 335,
            title: `Any title${this.deals.length}`,
            description: 'Any description',
            features: ['share_your_content', 'create_custom_content', 'ship_me_your_product', 'brand_mention', 'product_placement'],
            days_delivery: 15,
            revision: 2,
            licensing: 'sponsored_social_content'
          }
        ) 
      }
    },
    deleteDeal(deal) {
      if (deal.uuid) {
        creator_services.deleteElement(this.proposal.uuid, deal.uuid, 'deal').then(() => {
          this.deals = this.deals.filter((item) => item.uuid !== deal.uuid);
        });
      } else {
        this.deals = this.deals.filter((item) => item.index !== deal.index);
      }
      this.current_page = this.deals.length - 1;
    }
  },
}
</script>
<style>

.form-group-layout > label {
  text-align: left;
}
.btn-group-toggle {
  display:initial;
  /* text-align: left; */
}
.btn-group-toggle > label {
  margin: 0 1em 1em 0 !important;
}
.btn-group-toggle > .btn-secondary {
  /* display: flex !important; */
  width: auto !important;
  border-radius: 2em !important;
  background-color: white !important;
  border: 1px solid #b0b0b0 !important;
  border-right-color: #b0b0b0 !important;
  color: #b0b0b0 !important;
  box-shadow: none !important;
  padding: 0.5em 0.7em;
}
.btn-group-toggle > .active {
  background-color: #7367f0 !important;
  color: white !important;
  transition: all 300ms;
}
[dir=ltr] .btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):first-child, [dir=ltr] .btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):first-of-type,
[dir=ltr] .btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]),
[dir=ltr] .btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-child, [dir=ltr] .btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-of-type
{
  border-right-color: #b0b0b0 !important;
  border-left-color: #b0b0b0 !important;
}
</style>